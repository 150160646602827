import React from "react"
import { Disclaimer } from "trv-ebus-tcom-reactcomponents"
import logo from "../../../static/images/footer-logo.svg"
import "./styles.scss"
import AnchorLink from "../anchor-link/anchorLink"

const footerGroups = [
  {
    heading: "Products & Services",
    id: "footer-products-and-services-heading",
    links: [
      {
        text: "Commercial General Liability",
        href: "/products/commercial-general-liability"
      },
      {
        text: "Commercial Property",
        href: "/products/commercial-property-insurance"
      },
      { text: "Crime", href: "/products/crime-insurance" },
      { text: "Inland Marine", href: "/products/inland-marine-insurance" },
      { text: "Liquor Liability", href: "/products/liquor-liability" },
      {
        text: "Miscellaneous Professional Liability",
        href: "/products/miscellaneous-professional-liability"
      },
      { text: "Who We Cover", href: "/who-we-cover" },
      { text: "Services", href: "/services" },
      {
        text: "Travelers Incentive",
        href: "/services/incentives"
      }
    ]
  },
  {
    heading: "Our Company",
    id: "footer-company-heading",
    links: [
      { text: "About Us", href: "/about" },
      {
        text: "Travelers.com",
        href: "https://www.travelers.com",
        target: "_blank"
      },
      { text: "Careers", href: "/careers" }
    ]
  },
  {
    heading: "Connect",
    id: "footer-connect-heading",
    links: [
      { text: "Report a Claim", href: "/claim-reporting" },
      { text: "Find a General Agent", href: "/find-agent" },
      { text: "Contact Us", href: "/contact-us" },
      {
        text: (
          <span>
            Northfield<em>Online</em>
          </span>
        ),
        href: "https://signin.travelers.com/",
        target: "_blank"
      }
    ]
  },
  {
    heading: "Legal & Compliance",
    id: "footer-legal-and-compliance-heading",
    links: [
      {
        text: "Terms of Service",
        href: "https://www.travelers.com/about-travelers/legal",
        target: "_blank"
      },
      {
        text: "Privacy & Security",
        href: "https://www.travelers.com/about-travelers/privacy-statements",
        target: "_blank"
      },
      {
        text: "Cookie Settings",
        additionalClassName: "ot-sdk-show-settings",
        href: "javascript:void(0)",
        isDummy: true
      },
      {
        text: "Accessibility",
        href: "https://www.travelers.com/about-travelers/accessibility",
        target: "_blank"
      },
      {
        text: "Producer Compensation Disclosure",
        href: "https://www.travelers.com/about-travelers/producer-compensation-disclosure",
        target: "_blank"
      },
      { text: "California/NewYork Disclosure", href: "/company-licenses" }
    ]
  }
]

const GlobalFooter = ({ disclaimers }) => (
  <footer
    className="tds-global-footer"
    role="contentinfo"
    data-use-in-tearsheet>
    <div className="tds-global-footer__wrapper">
      <img
        src={logo}
        className="tds-global-footer__logo"
        alt="Northfield logo"
      />
      <p className="tds-global-footer__copy">
        &copy; {new Date().getFullYear()} The Travelers Indemnity Company. All
        rights reserved.
      </p>

      <div className="tds-global-footer__links">
        {footerGroups.map((group) => (
          <div className="tds-global-footer__link-group" key={group.id}>
            <h2 id={group.id} className="tds-global-footer__link-group-heading">
              {group.heading}
            </h2>
            <ul aria-labelledby={group.id}>
              {group.links.map((link, index) => (
                <li key={`${group.id}-link-item-${index}`}>
                  <AnchorLink
                    className={`tds-global-footer__link-item${link.additionalClassName?" " + link.additionalClassName : ""}`}
                    href={link.href}
                    target={link.target}
                    isDummy={link.isDummy}>
                    {link.text}
                  </AnchorLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <Disclaimer disclaimers={disclaimers} />
    </div>
  </footer>
)

export default GlobalFooter
