import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Navigation } from "trv-ebus-tcom-reactcomponents"
import setHighlightState from "../../utilities/setHighlightState"
import "./styles.scss"

const GlobalHeader = ({ pageUrl, showAlert, onAlertClick }) => {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          ecsNav {
            content
          }
        }
      `}
      render={(data) => (
        <Navigation
          navigation={data.ecsNav.content}
          logo="/images/header-logo.svg"
          logoHref="/"
          logoTitle="Northfield Home Page"
          logoClasses="northfield-hdr-logo"
          logoScreenReaderText="Northfield Logo"
          megaNavCoremetricsCategory="TCOM-MEGANAV"
          mobileNavCoremetricsCategory="TCOM-MOBILENAV"
          searchAction="/search-results"
          highlightCurrent={pageUrl && setHighlightState(pageUrl)}
          searchPlaceholder="Search northfieldins.com"
          showAlertIcon={showAlert}
          sitewideMessagesId="nf-sitewide-messages"
          onAlertClick={onAlertClick}
        />
      )}
    />
  )
}

export default GlobalHeader
