import React, { useEffect, useState } from "react"
import { SiteMessages } from "trv-ebus-tcom-reactcomponents"
import cookies from "../../utilities/cookies"

const setSitewideCookies = (messages) => {
  for (let i = 0; i < messages.length; i++) {
    const cookieId = `trv-swm-${messages[i].messageLocation}`
    if (!cookies.get(cookieId)) {
      cookies.set(cookieId, true)
    }
  }
}

const hasUnseenMessages = (siteMessages) => {
  for (let i = 0; i < siteMessages.length; i++) {
    const cookieId = `trv-swm-${siteMessages[i].messageLocation}`
    if (!cookies.get(cookieId)) {
      return true
    }
  }

  return false
}

const CollapsibleSiteMessages = ({ messageType, siteMessages, setAlert }) => {
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    const isCollapsed = hasUnseenMessages(siteMessages) ? false : true

    setIsHidden(isCollapsed)
    setAlert(isCollapsed)
  }, [])

  const handleDismiss = () => {
    setSitewideCookies(siteMessages)
    setAlert(true)
  }

  return (
    <SiteMessages
      id="nf-sitewide-messages"
      messageType={messageType}
      siteMessages={siteMessages}
      isHiddenOnInit={isHidden}
      onDismiss={handleDismiss}
    />
  )
}

export default CollapsibleSiteMessages
