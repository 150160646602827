const cookies = {
  get: function (c_name) {
    var doc = window.document
    if (doc.cookie.length > 0) {
      let c_start = doc.cookie.indexOf(c_name + "=")
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1
        let c_end = doc.cookie.indexOf(";", c_start)
        if (c_end == -1) c_end = doc.cookie.length
        return unescape(doc.cookie.substring(c_start, c_end))
      }
    }
    return ""
  },

  set: function (c_name, value, expiredays) {
    var doc = window.document
    var exdate = new Date()
    exdate.setDate(exdate.getDate() + expiredays)
    doc.cookie =
      c_name +
      "=" +
      escape(value) +
      (!expiredays ? "" : ";expires=" + exdate.toUTCString()) +
      ";path=/"
  },

  remove: function (name) {
    var exdate = new Date()
    exdate.setDate(exdate.getDate() - 1)
    Trv.Common.Cookie.set(name, "", exdate.getDate())
  },

  areCookiesEnabled: function () {
    var nav = Trv.Common.getNavigator()
    var cookieEnabled = nav.cookieEnabled ? true : false

    if (typeof nav.cookieEnabled == "undefined" && !cookieEnabled) {
      var doc = window.document
      doc.cookie = "testcookie"
      cookieEnabled = doc.cookie.indexOf("testcookie") != -1 ? true : false
    }
    return cookieEnabled
  }
}

export default cookies
