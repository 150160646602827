import React from "react"
import { useStore } from "react-redux"
import { Head } from "trv-ebus-tcom-reactcomponents"

const getShareaholic = () => {
  const store = useStore()
  const shareaholic = store.getState().config.SHAREAHOLIC
  return shareaholic
}

const Shareaholic = () =>
  getShareaholic() ? (
    <Head>
      <link
        rel="preload"
        href="https://cdn.shareaholic.net/assets/pub/shareaholic.js"
        as="script"
      />
      <meta
        name="shareaholic:site_id"
        content="69df61dda78796a19379870bc6927af2"
      />
      <script
        data-cfasync="false"
        async
        src="https://cdn.shareaholic.net/assets/pub/shareaholic.js"></script>
    </Head>
  ) : null

export default Shareaholic
