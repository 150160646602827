import React, { useState } from "react"
import DefaultHead from "../components/default-head"
import GlobalFooter from "../components/global-footer"
import GlobalHeader from "../components/global-header"
import CollapsibleSiteMessages from "../components/collapsible-site-messages"
import {
  DisclaimerContextProvider,
  LinkContextProvider
} from "trv-ebus-tcom-reactcomponents"
import AnchorLink from "../components/anchor-link/anchorLink"

const BaseLayout = ({
  head,
  disclaimers,
  breadcrumbs,
  sitewideMessages,
  globalHeader,
  children
}) => {
  const [showAlert, setShowAlert] = useState(false)

  return (
    <>
      <LinkContextProvider Link={AnchorLink}>
        <DisclaimerContextProvider>
          <DefaultHead {...head} />
          <div className="wrapper northfield">
            {sitewideMessages && (
              <CollapsibleSiteMessages
                {...sitewideMessages}
                setAlert={(isVisible) => setShowAlert(isVisible)}
              />
            )}
            <GlobalHeader
              {...globalHeader}
              showAlert={showAlert}
              onAlertClick={() => setShowAlert(false)}
            />
            {breadcrumbs}
            {children}
            <GlobalFooter disclaimers={disclaimers} />
          </div>
          {/* todo: social share modal component here - do we even use this component anymore? */}
        </DisclaimerContextProvider>
      </LinkContextProvider>
    </>
  )
}

export default BaseLayout
