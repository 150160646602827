import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

const AnchorLink = ({ isPreview, children, href = "", ...args }) => {
  const externalLinks = [
    "http",
    "www",
    "iw-images",
    "iw-documents",
    "/iw-images",
    "/iw-documents"
  ]

  let isExternalLink = false

  externalLinks.forEach((externalLink) => {
    if (href.startsWith(externalLink)) {
      isExternalLink = true
    }
  })

  if (args.isDummy) {
    return <a href={href} {...args}>{children}</a>
  }

  if (isExternalLink) {
    return <a href={href} {...args}>{children}</a>
  } else {
    let { target } = args

    if (target == "_blank") {
      return <a href={href} {...args}>{children}</a>
    }

    if (isPreview && (!href.startsWith('preview') && !href.startsWith('/preview'))) { 
      href = `/preview${href}`
    }

    return (
      <Link to={href} {...args}>
        {children}
      </Link>
    )
  }
}

const AnchorLinkWithPreview = ({ children, href = "", ...args }) => {
  return <StaticQuery
    query={graphql`
      query {
        ecsContentMode {
          content {
            isPreview
          }
        }
      }
    `}
    render={(data) => (
      <AnchorLink isPreview={data.ecsContentMode.content.isPreview} children={children} href={href} {...args} />
    )}
  />
}

export default AnchorLinkWithPreview