const setHighlightState = (pageUrl) => {
  if (pageUrl.indexOf("products") > -1) {
    return "main-nav-0"
  } else if (pageUrl.indexOf("who-we-cover") > -1) {
    return "main-nav-1"
  } else if (pageUrl.indexOf("services") > -1) {
    return "main-nav-2"
  } else if (pageUrl.indexOf("about") > -1) {
    return "main-nav-3"
  } else {
    return ""
  }
}

export default setHighlightState
